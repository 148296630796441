/* @font-face {
  font-family: 'Akzidenz-Grotesk-Pro';
  src: url(../public/assets/fonts/akzidenzgroteskpro_light.ttf);
}

@font-face {
  src: url(../public/assets/fonts/akzidenzgroteskpro_md.ttf);
} */

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #000;
  overflow-x: hidden;
  color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Fahkwang', sans-serif;

}

.fs-14 {
  font-size: 14px !important;
}

/* Header  */

.navbar {
  height: 85px;
  position: fixed;
  width: 100%;
  z-index: 111;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #61DFFF;
  transition: all .2s ease-in-out;
}

nav a.launch {
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #61DFFF;
  position: relative;
  text-align: center;
}

nav a.launch:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #61DFFF;
  height: 2px;
  transition: all .5s;
}

nav a.launch:hover:after {
  width: 100%;
}




/* .nav-ic {
  transition: all .5s ease-in-out;

}

.nav-ic:hover {
  transform: scale(1.1);
  transition: all .5s ease-in-out;

} */


.navbar-collapse.collapsing {
  transition: none !important;
}

.h-40 {
  height: 40px !important;
}

/* Banner */

.banner {
  padding-top: 150px;
  padding-bottom: 120px;
  overflow: auto;
  display: flex;
  align-items: center;
  /* background-image: url(../public/assets/bg1.png); */
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;
}

.banner h1 {
  font-weight: 700;
  font-size: 54px;
  line-height: 80px;
  text-transform: uppercase;
  color: #61DFFF;
}

.liquid{
  font-size: 96px;
}

.banner h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 12px;
  font-family: 'Poppins', sans-serif;

}

.banner p {
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #FFFFFF;
}

.primary-text {
  height: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  border-radius: 50px;
  height: 44px;
  width: 170px;
  padding: 8px 32px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  background: linear-gradient(0deg, #61DFFF, #61DFFF), linear-gradient(100.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  border-radius: 50px;
  height: 54px;
  width: 180px;
  padding: 14px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: linear-gradient(100deg, #61DFFF, #61DFFF), linear-gradient(0.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  transition: all .3s ease-in-out;
  color: white;
}

.primary-btn-outline {
  background: linear-gradient(0deg, #61DFFF, #61DFFF), linear-gradient(100.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  border-radius: 50px;
  display: inline-flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: white;
  height: 54px;
  max-height: 64px;
  width: 180px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: linear-gradient(100deg, #61DFFF, #61DFFF), linear-gradient(0.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  color: #fff;
  transition: all .1s ease-in-out;
}

.primary-btn-outline span {
  background: #000;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.primary-btn-outline:hover span {
  background: transparent !important;
  transition: all .3s ease-in-out;

}

.primary-badge {
  background: linear-gradient(90deg, #FD082B 1.43%, #E901B9 33.62%, #0850FF 61.84%, #06F8FF 95.52%);
  border-radius: 50px;
  display: inline-flex;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  color: white;
  height: 38px;
  max-height: 38px;
  width: 300px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-badge span {
  background: #000;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.left-aside,
.right-aside {
  width: 172px;
  margin-top: 60px;
  position: relative;
}

.left-aside p,
.right-aside p {
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  color: #808080;
  margin-bottom: 0px;
}

.left-aside::before {
  content: "";
  position: absolute;
  right: -10px;
  height: 70%;
  background: #808080;
  width: 2px;

}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}

.pkg-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}

.pkg-card {
  min-width: 1050px;
}

.top-banner{
  position: relative;
  height: 100vh;
  margin-bottom: 50px;
}

.top-banner::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg,rgb(0 0 0 / 50%) 40%,rgba(2,40,91,0) 60%);
  z-index: 1;
}


@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }
  

}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  nav a.launch:before {
    content: none
  }

  .banner {
    position: relative;
    z-index: 111;
    align-items: flex-start;
    height: auto;
    padding-top: 0;
    background-size: 70%;
    background-position: top right;
    padding-bottom: 30px;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 48px;
    padding-top: 0px;
  }
  .liquid{
    font-size: 36px;
  }

  .banner p {
    font-size: 20px;
    line-height: 30px;
  }

  .banner::before {
    content: none;
  }

  .banner p {
    margin-bottom: 35px;
  }

  .left-aside,
  .right-aside {
    margin-top: 45px;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 90%;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    border-right: 1px solid #6C6C6C;
    ;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }

  .by-1 {
    border-top: 1px solid #6C6C6C;
    border-bottom: 1px solid #6C6C6C;
    padding: 20px 0;
    margin-bottom: 20px;
    margin-top: 10px;
  }

}


/* how-it-work  */

.main-bg {
  background-image: url(../public/assets/main-bg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.how-it-work h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #fff;
}


.how-it-work p {
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  margin-bottom: 0px;

}

/* checkOut css  */
.check-out h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
}

.check-out h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.24px;
  color: #FFFFFF;
}

.check-out p {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.2px;
  color: white;
  margin-bottom: 25px;
}


/* pkg css  */

.pkg h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  color: #61DFFF;
}

.pkg-card {
  /* opacity: 0.8; */
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 34px;
  padding: 10px;
  padding-top: 100px;
  position: relative;
}

.head {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  color: white;
}

.head1 {
  padding: 20px 20px;
  color: white;
}

.price-card {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 20px;
  z-index: 11;
  width: 80%;
  height: 100%;
  margin-left: auto;
}

.v1-card,
.v2-card {
  height: 96%;
  border: 1px solid rgba(245, 245, 245, .3);
  border-radius: 24px;
  flex: 0 0 300px;
  text-align: center;
}

.v2-card {
  background: linear-gradient(180deg, rgba(0, 212, 255, 0.2) 12.61%, rgba(0, 172, 229, 0.2) 89.78%);
  width: 100%;
  flex: 1;
}

.pkg i {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  margin-top: 50px;
  display: block;
}


.v1-head {
  background: linear-gradient(180deg, #00D4FF 12.61%, #00ACE5 89.78%);
  ;
  padding: 36px 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.v2-head {
  background: linear-gradient(180deg, rgba(0, 212, 255, 0.5) 12.61%, rgba(0, 172, 229, 0.5) 89.78%);
  padding: 36px 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}


/* faqs css  */

.faq h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 0.18px;
  color: #61DFFF;
  margin-bottom: 40px;
}



.faq .accordion-item:first-of-type .accordion-button {
  background-color: transparent !important;
  color: #fff;
  border: 0;
  padding-right: 80px;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/-.svg);
  /* transform: rotate(-180deg); */
}

.faq .accordion-button:not(.collapsed)::before {
  background: transparent;
}

.faq .accordion-button::before {
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  position: absolute;
  content: "";
  right: 3px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  z-index: 0;
}

.faq .accordion-button::before :hover {
  background: transparent;
}

.faq .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../public/assets/+.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  z-index: 9;
  position: absolute;
  right: 20px;
}

.faq .accordion-button::after:hover {
  background-color: red;
}

.faq .accordion-item {
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid #61DFFF;
  padding: 5px 20px;
  margin-bottom: 15px;
}

.faq .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq .accordion-button {
  border-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.9);
  padding: 15px 60px 15px 0px !important;
}


.faq .accordion-body {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  padding-left: 0;
  color: rgba(255, 255, 255, 1);
}

.faq .accordion-body a {
  color: #06F4FF;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

/* partner css  */

.partner-card {
  border-radius: 32px;
  /* background: linear-gradient(180deg, rgba(0, 212, 255, 0.12) 12.61%, rgba(0, 172, 229, 0.12) 89.78%); */
  padding: 48px 32px;
  /* border: 1px solid #00d5ffb8; */
}

.partner-card h2 {
  color: #61DFFF;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 48px;

}
.partners .partner-img  {
 background: black;
}
.partners .partner-img img {
  mix-blend-mode: luminosity;
}

.dex-partner {
  background: url(../public/assets/dex-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
}


.col-01 {
  flex: 0 0 auto;
  width: 50%;
}

.partner-img img {
  max-width: 100%;
}

/* footer  */
footer{
  background: linear-gradient(180deg, #00D4FF 12.61%, #00ACE5 89.78%);
}

hr {
  background: linear-gradient(90deg, rgba(0, 172, 229, 0.50) 0%, rgba(0, 212, 255, 0.50) 52.21%, rgba(0, 172, 229, 0.50) 100%);
  opacity: 1;
  height: 1px;
  border: 0;
  margin: 0;
  width: 100%;
}

.footer-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
}

.footer-link:hover {
  color: #dae0ff;
  transform: translate(0px, -5px);
  transition: all .2s ease-in-out;
}
.no-hover:hover{
  transform: translate(0) !important;
  color: white !important;
  transition: all .2s ease-in-out !important;
}
.no-hover1:hover{
  transform: translate(0) !important;
}

.nav-ic,
.social-ic {
  color: #61dfff !important;
  font-size: 24px;
  transition: all .2s ease-in-out;
}
.nav-ic:hover,
.social-ic:hover {
  transform: translate(0px, -5px);
  transition: all .2s ease-in-out;
}

p.footer-link {
  font-size: 14px;
}


.content-box {
  background: url(../public/assets/content-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 32px;
}
.text-dark{
  color: black !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
 
  .how-it-work p{
    font-size: 16px ;
    line-height: 22px;
  }
}


#videobg {
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  .top-banner {
    height: auto;
}
#videobg {
  height: 75vh;
  width: 100%;
}
.top-banner::before {
  background: linear-gradient(90deg,rgb(0 0 0 / 12%) 100%,rgba(2,40,91,0) 100%);
}
  .banner img {
    width: 100%;
  }

  .dex-partner {
    padding: 00px 0;
  }

  .content-box {
    background: none;
  /* border: 1px solid #00d5ffb8; */
  border-radius: 32px;
  margin-bottom: 50px;
  padding: 25px;

  }

  .col-01 {
    flex: 0 0 auto;
    width: 90%;
  }

  .primary-btn-outline,
  .primary-btn {
    font-size: 18px;
    height: 48px;
  }

  .primary-badge {
    font-size: 14px;
  }

  h2 {
    font-size: 30px !important;
    line-height: 42px !important;
  }

  .partner {
    padding-top: 0;
  }

  .partner h2 {
    font-size: 50px !important;
    line-height: 62px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  .banner h3 {
    padding-top: 100px;
    margin-bottom: 25px;
  }

  .how-it-work h2 {
    font-size: 26px !important;
    line-height: 36px !important;

  }


  .how-it-work p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  .primary-text {
    font-size: 14px;
    height: 38px;
    width: 160px;
    padding: 10px 20px;
  }


  .pkg i {
    font-size: 18px;
    line-height: 26px;
  }

  .footer-logo {
    margin-bottom: 20px;
    text-align: center;
  }

  .faq .accordion-button {
    font-size: 18px;
    line-height: 20px;
    padding: 15px 40px 15px 0px !important;
  }

  .faq .accordion-body {
    font-size: 14px;
    line-height: 22px;
  }
  .faq .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 0.9rem;
    right: 15px;
}

  .check-out img {
    height: 50px;
  }

  .check-out p {
    font-size: 18px;
    line-height: 28px;
  }

  .bg2 {
    padding: 70px 0;
  }

  .bg2 h4 {
    font-size: 22px;
    line-height: 16px;
  }

  .sm-50 {
    width: 45%;
    padding-left: 30px;
  }

  .pkg-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .pkg-card {
    min-width: 1050px;
  }

  .partner-img img {
    max-width: 75%;
  }
  .faq .accordion-item {
    padding: 5px 0px 5px 20px;
}

  /* .pkg-card{
    padding-top: 10px;
  }

  .price-card{
    position: static;
    display: block;
    width: 100%;
  }
  .v1-card{
    margin-bottom: 30px;
  }
  .head1{
    border-bottom: 1px solid rgba(255, 255, 255, .3);
  } */
}

@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
      max-width: 1080px;
  }
  }
@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px;
  }
}


.r1{
  padding: 50px;
  border-radius: 32px;
/* background: linear-gradient(100deg, rgba(0, 212, 255, 0.12) 25.61%, rgba(0, 172, 229, 0.2) 89.78%); */
background: linear-gradient(40deg,#002f47,#003e4700);
/* text-align: center; */
margin-bottom: 100px;
position: relative;
overflow: hidden;
}
.g2{
 background: linear-gradient(280deg,#002f47,#003e4700) !important;

}
/* 
.how-it-work img{
  mix-blend-mode: color-dodge;
} */

.r1 .content-box, .r2 .content-box{
  background: none;
  padding: 0;
}

.r2{
  padding: 50px;
  border-radius: 32px;
text-align: center;
margin-bottom: 100px;
position: relative;

}

.r2:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  border: 2px solid #61DFFF;
  border-radius: 2.5rem;
  -webkit-mask-image: radial-gradient(ellipse farthest-corner at 0 0% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
  transition: all .6s ease-in-out;
  transition-delay: 2.5s;
  -webkit-animation: in-width-down 1.6s ease-in-out 0s infinite both;
  animation: in-width-down 1.6s ease-in-out 0s infinite both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  opacity: 1;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
} 

.r2:after:hover {
  opacity: 1;
  -webkit-animation-play-state: running !important;
  animation-play-state: running !important;
}

.aos-animate{
  position: relative;
}
.r1.aos-animate:after {
  transform: translate(400%) scale(1.5,1.1);
  transition: all 1.6s cubic-bezier(.785,.135,.15,.86);
  transition-delay: .4s;
}


/* .r1:after {
  content: "";
  width: 60rem;
  height: 100%;
  display: block;
  position: absolute;
  top: -0%;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 10;
  background: #00e1ff29;
  border-radius: 32px;
  mix-blend-mode: soft-light;
  filter: blur(10rem);
  opacity: 1;
  transform: translate(-100%,-20%) scale(.5,1.8);
}
.r1.aos-animate:after {
  transform: translate(400%) scale(1.5,1.1);
  transition: all 1.6s cubic-bezier(.785,.135,.15,.86);
  transition-delay: .4s;
} */

@media (max-width: 767px) {
    .r1{
      padding: 30px 20px;
      margin-bottom: 50px;
    }
    .r1,.g2{
 background: linear-gradient(180deg,#00e0ff4a,rgba(2,40,91,0));

    }
}

@keyframes in-width-down {
  0% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 0 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 0 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  1% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 1% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 1% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  2% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 2% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 2% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  3% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 3% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 3% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  4% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 4% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 4% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  5% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 5% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 5% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  6% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 6% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 6% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  7% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 7% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 7% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  8% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 8% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 8% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  9% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 9% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 9% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  10% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 10% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 10% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  11% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 11% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 11% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  12% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 12% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 12% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  13% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 13% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 13% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  14% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 14% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 14% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  15% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 15% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 15% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  16% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 16% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 16% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  17% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 17% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 17% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  18% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 18% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 18% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  19% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 19% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 19% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  20% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 20% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 20% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  21% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 21% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 21% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  22% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 22% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 22% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  23% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 23% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 23% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  24% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 24% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 24% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  25% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 25% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 25% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  26% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 26% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 26% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  27% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 27% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 27% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  28% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 28% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 28% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  29% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 29% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 29% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  30% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 30% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 30% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  31% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 31% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 31% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  32% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 32% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 32% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  33% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 33% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 33% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  34% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 34% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 34% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  35% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 35% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 35% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  36% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 36% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 36% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  37% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 37% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 37% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  38% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 38% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 38% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  39% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 39% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 39% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  40% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 40% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 40% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  41% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 41% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 41% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  42% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 42% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 42% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  43% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 43% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 43% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  44% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 44% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 44% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  45% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 45% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 45% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  46% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 46% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 46% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  47% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 47% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 47% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  48% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 48% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 48% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  49% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 49% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 49% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  50% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 50% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 50% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  51% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 51% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 51% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  52% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 52% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 52% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  53% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 53% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 53% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  54% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 54% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 54% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  55% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 55% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 55% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  56% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 56% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 56% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  57% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 57% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 57% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  58% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 58% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 58% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  59% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 59% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 59% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  60% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 60% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 60% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  61% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 61% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 61% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  62% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 62% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 62% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  63% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 63% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 63% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  64% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 64% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 64% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  65% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 65% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 65% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  66% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 66% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 66% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  67% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 67% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 67% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  68% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 68% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 68% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  69% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 69% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 69% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  70% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 70% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 70% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  71% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 71% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 71% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  72% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 72% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 72% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  73% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 73% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 73% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  74% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 74% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 74% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  75% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 75% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 75% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  76% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 76% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 76% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  77% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 77% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 77% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  78% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 78% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 78% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  79% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 79% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 79% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  80% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 80% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 80% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  81% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 81% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 81% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  82% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 82% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 82% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  83% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 83% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 83% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  84% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 84% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 84% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  85% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 85% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 85% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  86% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 86% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 86% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  87% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 87% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 87% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  88% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 88% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 88% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  89% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 89% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 89% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  90% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 90% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 90% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  91% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 91% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 91% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  92% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 92% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 92% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  93% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 93% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 93% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  94% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 94% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 94% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  95% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 95% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 95% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  96% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 96% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 96% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  97% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 97% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 97% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  98% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 98% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 98% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  99% {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 99% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 99% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }

  to {
      -webkit-mask-image: radial-gradient(ellipse farthest-corner at 100% 100%,#000 0,transparent 50%,transparent 75%,#000 100%);
      mask-image: radial-gradient(ellipse farthest-corner at 100% 100%,#000 0,transparent 50%,transparent 75%,#000 100%)
  }
}